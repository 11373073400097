import React, { useEffect } from 'react';
import CmsContent from 'components/CmsContent';
import ErrorBoundary from 'components/ErrorBoundary';
import track from 'react-tracking';
import { tagComponent } from 'utils/tracking/tracking';
import useExperimentPageCode from 'pages/CmsPage/useExperimentPageCode';

type TrackingEvent = {
  event: string;
  [key: string]: any;
};

interface CmsPageProps {
  match: {
    params: {
      cmsPageCode: string;
    };
  };
  tracking: {
    trackEvent: (event: TrackingEvent) => void;
  };
}

const CmsPage = ({
  match: {
    params: { cmsPageCode },
  },
  tracking,
}: CmsPageProps) => {
  const pageCode = useExperimentPageCode(cmsPageCode);

  useEffect(() => {
    tracking.trackEvent({ event: 'pageShown' });
  }, [pageCode, tracking]);

  return (
    <ErrorBoundary withStatusCode>
      <CmsContent pageCode={pageCode} seo showLoading redirectOnError />
    </ErrorBoundary>
  );
};

export default track(tagComponent('CmsPage'), {
  dispatchOnMount: () => ({ event: 'pageShown' }),
})(CmsPage);
